import React from "react";
import { Container } from "@mui/material";
import { Route, Routes, useLocation } from "react-router-dom";
import UploadDesign from "./features/UploadDesign/UploadDesign";
import Login from "./features/Authentication/Login";
import PrivateRouter from "./components/PrivateRoute";
import Dashboard from "./features/Dashboard/Dashboard";
import { getAgentState } from "./features/Authentication/AuthenticationSlice";
import { useSelector } from "react-redux";
import SecureLS from "secure-ls";
import Header from "./components/Header";
import logo from "../src/assets/new-logo.svg";
import Otp from "./features/Authentication/Otp";
import ForgotPassword from "./features/Authentication/ForgotPassword";
import ResetPassword from "./features/Authentication/ResetPassword";
import VerifySecurityQuestion from "./features/Authentication/VerifySecurityQuestion";
import SecurityQuestions from "./features/Authentication/SecurityQuestions";
import ListDesigns from "./features/UploadDesign/Admin/ListDesigns";
import Navbar from "./components/Navbar";
import ViewDesign from "./features/UploadDesign/Admin/ViewDesign";
import Http404 from "./components/Http404";

function App() {
  const ls = new SecureLS({ encodingType: "aes" });
  const loggedinAgent = ls.get("log_meta");
  const isLoggedIn = useSelector(getAgentState);
  const location = useLocation();
  return (
    <>
      {!!isLoggedIn?.isAuthenticated && location.pathname.includes("admin") && (
        <Navbar image={logo} />
      )}
      <Container maxWidth="xl">
        <Routes>
          <Route exact path="/upload-design" element={<UploadDesign />}></Route>
          <Route exact path="/login" element={<Login />}></Route>
          <Route exact path="/agent/otp" element={<Otp />} />
          <Route
            exact
            path="/agent/forgotPassword"
            element={<ForgotPassword />}
          />
          <Route
            exact
            path="/agent/resetPassword/:agentUUID"
            element={<ResetPassword />}
          />
          <Route
            exact
            path="/agent/verifySecurityQuestion"
            element={<VerifySecurityQuestion />}
          />
          <Route
            exact
            path="/agent/securityQuestions"
            element={<SecurityQuestions />}
          />
          <Route
            exact
            path="/admin/dashboard"
            element={
              <PrivateRouter>
                <Dashboard user={loggedinAgent} />
              </PrivateRouter>
            }
          ></Route>
          <Route
            exact
            path="/admin/designs"
            element={
              <PrivateRouter>
                <ListDesigns />
              </PrivateRouter>
            }
          ></Route>
          <Route
            exact
            path="/admin/designs/:designId"
            element={
              <PrivateRouter>
                <ViewDesign />
              </PrivateRouter>
            }
          ></Route>
          {/* Route to 404 */}
        <Route exact path="*" element={<Http404 />} />
        </Routes>
      </Container>
    </>
  );
}

export default App;
