import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listDesigns } from "../UploadDesignApis";
import {
  getDesignListState,
  revertListImages,
  revertViewDesign,
  revertViewImages,
} from "../UploadDesignSlice";
import Loading from "../../../components/Loading";
import CircleIcon from "@mui/icons-material/Circle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Swal from "sweetalert2";
import SecureLS from "secure-ls";
import { revertAll } from "../../Authentication/AuthenticationSlice";

const ListDesigns = () => {
  const ls = new SecureLS({ encodingType: "aes" });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [clearFilter, setClearFilter] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });
  const nextPage = paginationModel.page + 1;
  const columns = [
    {
      field: "darekDesignRegistrationNo",
      headerName: "Reg Id",
      width: 200,
      headerClassName: "customHeader",
    },
    {
      field: "owner",
      headerName: "Owner",
      width: 220,
      headerClassName: "customHeader",
    },
    {
      field: "title",
      headerName: "Title",
      width: 230,
      headerClassName: "customHeader",
    },
    {
      field: "style",
      headerName: "Style",
      width: 200,
      headerClassName: "customHeader",
    },
    {
      field: "area",
      headerName: "Area (Sqft)",
      width: 200,
      headerClassName: "customHeader",
      renderCell: (params) => (params.value === null ? "---" : params.value),
    },
    {
      field: "darekStatus",
      headerName: "Status",
      width: 200,
      headerClassName: "customHeader",
      renderCell: (params) =>
        params.value === 0 ? (
          <>
            <CircleIcon fontSize="16" color="warning" />
            <Typography
              variant="body2"
              sx={{ textTransform: "uppercase", marginLeft: ".5rem" }}
            >
              Pending
            </Typography>
          </>
        ) : params.value === 1 ? (
          <>
            <CircleIcon fontSize="16" color="success" />
            <Typography
              variant="body2"
              sx={{ textTransform: "uppercase", marginLeft: ".5rem" }}
            >
              Approved
            </Typography>
          </>
        ) : params.value === 2 ? (
          <>
            <CircleIcon fontSize="16" color="error" />
            <Typography
              variant="body2"
              sx={{ textTransform: "uppercase", marginLeft: ".5rem" }}
            >
              Rejected
            </Typography>
          </>
        ) : null,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      headerClassName: "customHeader",
      renderCell: (params) => (
        <Tooltip title="View">
          <IconButton
            size="small"
            color="primary"
            onClick={() => handleView(params)}
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  useEffect(() => {
    dispatch(revertViewDesign());
    dispatch(revertListImages());
    dispatch(revertViewImages());
  }, []);

  const schema = Yup.object().shape({
    regNo: Yup.string(),
    status: Yup.string(),
  });

  const { handleSubmit, control, reset } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const handleView = (params) => {
    console.log(params);
    const designId = params.row.darekDesignRegistrationNo;
    navigate(`/admin/designs/${designId}`, {
      replace: true,
    });
  };

  const getDesignList = async (data) => {
    await dispatch(listDesigns(data)).unwrap();
  };

  useEffect(() => {
    const payload = {
      data: {
        darekDesignRegistrationNo: null,
        darekStatus: null,
        isActive: 0,
      },
      offset: parseInt(paginationModel.pageSize * (nextPage - 1)),
      limit: paginationModel.pageSize,
    };
    getDesignList(payload);

    return () => {
      setClearFilter(false);
    };
  }, [paginationModel, clearFilter]);

  const _rsDesignsList = useSelector(getDesignListState);

  useEffect(() => {
    if (_rsDesignsList?.apiStatus === "failed") {
      Swal.fire({
        icon: "error",
        title: "Uh Oh!!",
        text: _rsDesignsList?.message
          ? _rsDesignsList?.message === "UNAUTHORIZED"
            ? "Session expired. Please login again!!"
            : _rsDesignsList?.message
          : "Something went wrong while loading the designs. Please try again!!",
        confirmButtonColor: "#bea76c",
        allowOutsideClick: false,
      }).then((res) => {
        if (res.isConfirmed) {
          if (_rsDesignsList?.message === "UNAUTHORIZED") {
            ls.clear();
            dispatch(revertAll());
            navigate("/login", { replace: true });
          }
        }
      });
    }
  }, [_rsDesignsList]);

  const filterList = (values) => {
    const payload = {
      data: {
        darekDesignRegistrationNo: values.regNo ? values.regNo : null,
        darekStatus: values.status ? values.status : null,
        isActive: 0,
      },
      offset: parseInt(paginationModel.pageSize * (nextPage - 1)),
      limit: paginationModel.pageSize,
    };
    getDesignList(payload);
  };

  const handleClear = () => {
    setClearFilter(true);
    reset();
  };

  return (
    <Paper sx={{ padding: "1rem", margin: "1rem 0" }}>
      <Typography
        variant="h5"
        color="primary"
        sx={{ textTransform: "uppercase" }}
      >
        Designs
      </Typography>
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        sx={{ margin: "1rem 0" }}
      />
      <Box sx={{ margin: "1rem 0" }}>
        <form onSubmit={handleSubmit(filterList)}>
          <Grid container spacing={1} sx={{ justifyContent: "flex-end" }}>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth margin="normal">
                <Controller
                  name="regNo"
                  control={control}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      error={!!fieldState?.error}
                      helperText={fieldState?.error?.message}
                      size="small"
                      label="Registration No."
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth margin="normal">
                <Controller
                  name="status"
                  control={control}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      error={!!fieldState?.error}
                      helperText={fieldState?.error?.message}
                      size="small"
                      label="Status"
                      select
                    >
                      {[
                        { id: 0, label: "Pending" },
                        { id: 1, label: "Approved" },
                        { id: 2, label: "Rejected" },
                      ].map((item, i) => (
                        <MenuItem key={i} value={item.id}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={1}>
              <FormControl fullWidth margin="normal">
                <Button variant="contained" color="primary" type="submit">
                  Filter
                </Button>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={1}>
              <FormControl fullWidth margin="normal">
                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={handleClear}
                >
                  Clear
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              "& .customHeader": {
                backgroundColor: "#bea76c",
                color: "#fff",
              },
              width: "100%",
              overflowX: "auto",
            }}
          >
            <DataGrid
              sx={{
                "& .MuiDataGrid-virtualScroller": {
                  overflow: "auto",
                },
              }}
              rows={_rsDesignsList?.data?.content || []}
              columns={columns}
              autoHeight={true}
              getRowId={(row) => row.darekDesignRegistrationNo}
              disableRowSelectionOnClick
              rowCount={_rsDesignsList?.data.totalRows}
              pageSize={rowsPerPage}
              page={currentPage}
              pagination
              paginationMode="server"
              paginationModel={paginationModel}
              pageSizeOptions={[10, 25, 50, 100]}
              onPaginationModelChange={setPaginationModel}
              keepNonExistentRowsSelected
            />
          </Grid>
        </Grid>
      </Box>
      <Loading open={_rsDesignsList?.apiStatus === "pending"} />
    </Paper>
  );
};

export default ListDesigns;
