import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import darekLogo from "../../assets/new-logo.svg";
import Header from "../../components/Header";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FileUpload from "../../components/FileUpload";
import { features, styles } from "./Utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import {
  deactivateRegistration,
  generateToken,
  saveDocDataDb,
  submitData,
  uploadDocsToS3Api,
} from "./UploadDesignApis";
import {
  getDocsToDb,
  getDocsToDbState,
  getDocsToS3State,
  getSubmitDataState,
  getTokenState,
} from "./UploadDesignSlice";
import SecureLS from "secure-ls";
import Loading from "../../components/Loading";
import Swal from "sweetalert2";
import moment from "moment/moment";

const UploadDesign = () => {
  const ls = new SecureLS({ encodingType: "aes" });
  const schema = Yup.object().shape({
    owner: Yup.string().required("*Required!!"),
    title: Yup.string().required("*Required!!"),
    style: Yup.string().required("*Required!!"),
    area: Yup.string().required("*Required!!"),
    bedroom: Yup.string().required("*Required!!"),
    bathroom: Yup.string().required("*Required!!"),
    features: Yup.array()
      .of(Yup.string())
      .test(
        "at-least-one-selected",
        "Please select at least one checkbox!!",
        function (value) {
          return value && value.some((checked) => checked);
        }
      )
      .nullable(),
    description: Yup.string().required("*Required!!"),
    designDocuments: Yup.array()
      .min(1, "Please upload at least one file!!")
      .max(5, "Maximum allowed number of files is 5!!")
      .test(
        "fileValidation",
        "Please upload at least one file!!",
        function (value, { createError }) {
          console.log(value, "valueeeeeeeeeeeeeee");
          const maxAllowedSize = 2097152;
          let isValid = null;
          if (value && value?.length <= 0) {
            isValid = false;
          } else {
            const isInValidType = value.some(
              (elm) =>
                elm.type !== "image/jpeg" &&
                elm.type !== "image/jpg" &&
                elm.type !== "image/png"
            );
            const isInValidSize = value.some(
              (elm) => elm.size >= maxAllowedSize
            );
            if (isInValidType) {
              isValid = createError({
                message:
                  "Invalid file type. Please upload only images (jpg/png)!!",
              });
            } else if (isInValidSize) {
              isValid = createError({
                message: "Invalid file size. Please upload files below 2mb!!",
              });
            } else {
              isValid = true;
            }
          }
          return isValid;
        }
      )
      .nullable(),
  });
  const {
    control,
    handleSubmit,
    watch,
    ref,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const dispatch = useDispatch();

  //dispatch api call to generate token
  useEffect(() => {
    dispatch(generateToken());
  }, []);

  //get promise values of token api call from slice
  const _rsTokenState = useSelector(getTokenState);

  //handle generate token api call promise cases from redux toolkit
  useEffect(() => {
    //when api fails show alert message
    if (_rsTokenState?.apiStatus === "failed") {
      Swal.fire({
        icon: "error",
        title: "Uh Ohh!!",
        text: "Something went wrong while generating a valid page. Please refresh to try again!!",
        confirmButtonColor: "#8f6d26",
        confirmButtonText: "Refresh page",
      }).then((res) => {
        if (res.isConfirmed) {
          window.location.reload();
        }
      });
    }
    //when api success set token to localstorage
    if (_rsTokenState?.apiStatus === "success") {
      ls.set("pubToken", _rsTokenState?.data?.token);
    }
  }, [_rsTokenState]);

  const submitForm = async (values) => {
    const filteredFeatures = values.features.filter(
      (elem) => elem !== "false" && elem !== ""
    );
    const payload = {
      owner: values.owner,
      title: values.title,
      style: values.style,
      bedrooms: parseInt(values.bedroom),
      bathrooms: parseInt(values.bathroom),
      features: filteredFeatures,
      description: values.description,
      sourceId: 26,
      darekstatus: 0,
      area: parseInt(values.area),
    };
    await dispatch(submitData(payload)).unwrap();
  };

  //get promise values of submitData api call from slice
  const _rsRegisterState = useSelector(getSubmitDataState);

  //handle submitData api call promise cases from redux toolkit
  useEffect(() => {
    if (_rsRegisterState?.apiStatus === "failed") {
      Swal.fire({
        icon: "error",
        title: "Uh Ohh!!",
        text: "Something went wrong while registering your information. Please try again!!",
        confirmButtonColor: "#8f6d26",
      }).then((res) => {
        if (res.isConfirmed) {
          reset();
          window.location.reload();
        }
      });
    }
    if (_rsRegisterState?.apiStatus === "success") {
      saveDocumentData();
    }
  }, [_rsRegisterState]);

  //function to change the file name : append date and id
  const changeFileName = (file, regId) => {
    const fileName = file.name;
    const newFileName =
      fileName
        .split(".")
        .slice(0, -1)
        .join(".")
        .toLowerCase()
        .split(/[^a-zA-Z0-9]/gi)
        .join("")
        .toLowerCase() +
      "_" +
      moment()
        .format("DD-MM-YYYY")
        .split(/[^a-zA-Z0-9]/gi)
        .join("") +
      "_" +
      regId +
      "." +
      file.name.split(".").pop();

    const newFile = new File([file], `${newFileName}`, {
      type: file.type,
    });
    return newFile;
  };

  //deactivate the registration if and api after registration fails
  const deactivateRegister = async (notes) => {
    const payload = {
      registrationId: _rsRegisterState?.data?.registrationId,
      notes: notes,
    };
    await dispatch(deactivateRegistration(payload)).unwrap();
  };

  //get promise values of saveDocumentData api call from slice
  const _rsDocsToDbState = useSelector(getDocsToDbState);
  //watch documents
  const selectedDocs = watch("designDocuments");

  //function to save document information to db
  const saveDocumentData = async () => {
    const docs = [];
    console.log("docs:::::", selectedDocs);
    selectedDocs &&
      selectedDocs.map((doc, i) => {
        const changedFile = changeFileName(
          doc,
          _rsRegisterState?.data?.registrationId
        );
        docs.push({
          docName: changedFile.name,
          docTypeID: 13,
        });
      });
    const payload = {
      reqDoc: {
        docName: "",
        docTypeID: "",
      },
      multipleDoc: docs,
      applicationRequestID: _rsRegisterState?.data?.registrationId,
      sourceID: 26,
      agentID: 47,
    };
    await dispatch(saveDocDataDb(payload)).unwrap(); //dispatch api call to save document information
  };

  //handle saveDocumentData api call promise cases from redux toolkit
  useEffect(() => {
    if (_rsDocsToDbState?.apiStatus === "failed") {
      Swal.fire({
        icon: "error",
        title: "Uh Ohh!!",
        text: "Something went wrong while saving the file information. Please try again!!",
        confirmButtonColor: "#8f6d26",
      }).then((res) => {
        if (res.isConfirmed) {
          deactivateRegister("Failed to save file data to db");
        }
      });
    }
    if (_rsDocsToDbState?.apiStatus === "success") {
      uploadDocsToS3();
    }
  }, [_rsDocsToDbState]);

  //function to upload files to s3
  const uploadDocsToS3 = async () => {
    const filesToS3 = new FormData();
    selectedDocs.map((doc) => {
      const docFile = new File(
        [doc],
        `${changeFileName(doc, _rsRegisterState?.data?.registrationId).name}`,
        {
          type: doc.type,
        }
      );
      filesToS3.append("file", docFile);
    });
    await dispatch(uploadDocsToS3Api(filesToS3)).unwrap(); //dispatch api call to upload files
  };

  //get promise values of uploadDocsToS3 api call store
  const _rsDocsToS3 = useSelector(getDocsToS3State);

  //handle promise cases of uploadDocsToS3 api call
  useEffect(() => {
    if (_rsDocsToS3.apiStatus === "failed") {
      Swal.fire({
        icon: "error",
        title: "Uh Ohh!!",
        text: "Something went wrong while saving the file information. Please try again!!",
        confirmButtonColor: "#8f6d26",
      }).then((res) => {
        if (res.isConfirmed) {
          deactivateRegister("failed to upload file to s3");
        }
      });
    }
    if (_rsDocsToS3.apiStatus === "success") {
      Swal.fire({
        icon: "success",
        title: "Thank you!!",
        text: "The design upload is successfull!!",
        confirmButtonColor: "#8f6d26",
      }).then((res) => {
        if (res.isConfirmed) {
          reset();
          window.location.reload();
        }
      });
    }
  }, [_rsDocsToS3]);

  return (
    <Paper sx={{ margin: "2rem 0", padding: "1rem " }} elevation={0}>
      <Header image={darekLogo} title="Upload Designs" />
      <hr />
      <Box sx={{ marginTop: "2rem" }}>
        <form onSubmit={handleSubmit(submitForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense">
                <Controller
                  name="owner"
                  defaultValue=""
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label="Owner"
                      size="small"
                      error={!!fieldState?.error}
                      helperText={fieldState?.error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense">
                <Controller
                  name="title"
                  defaultValue=""
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label="Title"
                      size="small"
                      error={!!fieldState?.error}
                      helperText={fieldState?.error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense">
                <Controller
                  name="style"
                  defaultValue=""
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label="Style"
                      size="small"
                      error={!!fieldState?.error}
                      helperText={fieldState?.error?.message}
                      select
                    >
                      {styles.map((style) => (
                        <MenuItem key={style.value} value={style.label}>
                          {style.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense">
                <Controller
                  name="area"
                  defaultValue=""
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label="Area"
                      size="small"
                      error={!!fieldState?.error}
                      helperText={fieldState?.error?.message}
                      type="number"
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense">
                <Controller
                  name="bedroom"
                  defaultValue=""
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label="Bedroom"
                      size="small"
                      error={!!fieldState?.error}
                      helperText={fieldState?.error?.message}
                      type="number"
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense">
                <Controller
                  name="bathroom"
                  defaultValue=""
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label="Bathroom"
                      size="small"
                      error={!!fieldState?.error}
                      helperText={fieldState?.error?.message}
                      type="number"
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">Features</Typography>
              <Grid container>
                {features.map((item, i) => (
                  <Grid item xs={12} md={6} key={i}>
                    <FormControl>
                      <Controller
                        name={`features[${i}]`}
                        control={control}
                        ref={ref}
                        defaultValue=""
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...field}
                                checked={field.value === item.value}
                                onChange={(e) => {
                                  field.onChange(
                                    e.target.checked && item.value
                                  );
                                }}
                              />
                            }
                            label={item.label}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                ))}
                {!!errors.features && (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {errors?.features?.message}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense">
                <Controller
                  name="description"
                  defaultValue=""
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label="Description"
                      size="small"
                      error={!!fieldState?.error}
                      helperText={fieldState?.error?.message}
                      multiline
                      rows={7}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="dense">
                <Controller
                  control={control}
                  name="designDocuments"
                  ref={ref}
                  defaultValue={[]}
                  render={({ field }) => (
                    <FileUpload
                      {...field}
                      isMultiple={true}
                      label="Upload images"
                      name="designDocuments"
                      watch={watch}
                      error={errors}
                    />
                  )}
                />
              </FormControl>
              {!!errors.designDocuments && (
                <FormHelperText sx={{ color: "#d32f2f" }}>
                  {errors?.designDocuments?.message}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid
                container
                spacing={2}
                sx={{
                  justifyContent: "flex-end",
                  flexDirection: { xs: "column-reverse", sm: "row" },
                }}
              >
                <Grid item xs={12} sm={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    type="submit"
                  >
                    submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Loading
        open={
          _rsTokenState?.apiStatus === "pending" ||
          _rsRegisterState?.apiStatus === "pending" ||
          _rsDocsToDbState?.apiStatus === "pending" ||
          _rsDocsToS3?.apiStatus === "pending"
        }
      />
    </Paper>
  );
};

export default UploadDesign;
