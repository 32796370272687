import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getIsAuthenticated } from "../features/Authentication/AuthenticationSlice";

const PrivateRouter = ({ children }) => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  return children;
};

export default PrivateRouter;