export const features = [
  {
    label: "Garden",
    value: "Garden",
  },
  {
    label: "Maids Room",
    value: "Maids Room",
  },
  {
    label: "Electricity Backup",
    value: "Electricity Backup",
  },
  {
    label: "Storage Areas",
    value: "Storage Areas",
  },
  {
    label: "Barbecue",
    value: "Barbecue",
  },
  {
    label: "Study Room",
    value: "Study Room",
  },
  {
    label: "Gym",
    value: "Gym",
  },
  {
    label: "Pool",
    value: "Pool",
  },
];

export const styles = [
  {
    value: 1,
    label: "Classic",
  },
  {
    value: 2,
    label: "Islamic",
  },
  {
    value: 3,
    label: "New Classic",
  },
  {
    value: 4,
    label: "Traditional",
  },
  {
    value: 5,
    label: "Modern",
  },
];

