import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { downloadimages, takeAction, viewDesigns } from "../UploadDesignApis";
import {
  getDesignViewState,
  getRegActionState,
  getViewImagesState,
} from "../UploadDesignSlice";
import Swal from "sweetalert2";
import Loading from "../../../components/Loading";
import SecureLS from "secure-ls";
import { revertAll } from "../../Authentication/AuthenticationSlice";
import VisibilityIcon from "@mui/icons-material/Visibility";

const ViewDesign = () => {
  const { designId } = useParams();
  const ls = new SecureLS({ encodingType: "aes" });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [thisDesign, setThisDesign] = useState({});
  const [hoverIndex, setHoverIndex] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const getSingleDesign = async (regNo) => {
    const payload = {
      darekDesignRegistrationNo: regNo,
    };
    await dispatch(viewDesigns(payload)).unwrap();
  };

  const agentInfo = ls.get("log_meta");

  const viewImages = async (id, sourceId) => {
    const payload = {
      registrationID: id,
      sourceId: sourceId,
    };
    await dispatch(downloadimages(payload)).unwrap();
  };
  useEffect(() => {
    getSingleDesign(designId);
    viewImages(designId, 26);
  }, [designId, refresh]);

  const transformObject = (obj) => {
    const transformedObj = {};
    for (let key in obj) {
      const capitalizedKey = key
        .replace(/([A-Z])/g, " $1") // Add space before uppercase letters
        .trim() // Trim any leading or trailing spaces
        .toLowerCase() // Convert to lowercase
        .replace(/^\w/, (c) => c.toUpperCase()); // Capitalize the first letter

      let value = obj[key];
      if (typeof value === "string" && value.includes(",")) {
        value = value.split(",").map((item) => item.trim());
      }

      transformedObj[capitalizedKey] = value;
    }
    return transformedObj;
  };

  const handleTransformData = () => {
    const transformedObj = transformObject(_rsViewDesign?.data);
    setThisDesign(transformedObj);
  };

  const _rsViewDesign = useSelector(getDesignViewState);

  useEffect(() => {
    if (_rsViewDesign?.apiStatus === "failed") {
      Swal.fire({
        icon: "error",
        title: "Uh ohh!",
        confirmButtonColor: "#8f6d26",
        text: _rsViewDesign?.message
          ? _rsViewDesign?.message === "UNAUTHORIZED"
            ? "Session expired. Please login again!!"
            : _rsViewDesign?.message
          : "Unable to load the design. Please try again later!!",
        allowOutsideClick: false,
      }).then((res) => {
        if (res.isConfirmed) {
          if (_rsViewDesign?.message === "UNAUTHORIZED") {
            ls.clear();
            dispatch(revertAll());
            navigate("/login", { replace: true });
          } else {
            navigate("/admin/designs", { replace: true });
          }
        }
      });
    }
    if (_rsViewDesign?.apiStatus === "success") {
      handleTransformData();
    }
  }, [_rsViewDesign]);

  const _rsViewImages = useSelector(getViewImagesState);

  useEffect(() => {
    if (_rsViewImages?.apiStatus === "failed") {
      Swal.fire({
        icon: "error",
        title: "Uh ohh!",
        confirmButtonColor: "#8f6d26",
        text: _rsViewDesign?.message
          ? _rsViewDesign?.message === "UNAUTHORIZED"
            ? "Session expired. Please login again!!"
            : _rsViewDesign?.message
          : "Unable to load the design. Please try again later!!",
        allowOutsideClick: false,
      }).then((res) => {
        if (res.isConfirmed) {
          if (_rsViewDesign?.message === "UNAUTHORIZED") {
            ls.clear();
            dispatch(revertAll());
            navigate("/login", { replace: true });
          } else {
            navigate("/admin/designs", { replace: true });
          }
        }
      });
    }
  }, [_rsViewImages]);

  const handleShowOverlay = (index) => {
    setHoverIndex(index);
  };

  const handleHideOverlay = () => {
    setHoverIndex(null);
  };

  const popupImage = (i) => {
    Swal.fire({
      html: `<div style="margin-top: 2rem;"><img width="100%" src=data:application/pdf;base64,${_rsViewImages?.data[i]} alt='file_'${i} /></div>`,
      showCloseButton: true,
      showConfirmButton: false,
      width: "65%",
      allowOutsideClick: false,
    });
  };

  const submitAction = async (statusFlag) => {
    Swal.fire({
      title: "Enter Notes",
      input: "text",
      inputLabel: "",
      inputValue: "",
      confirmButtonColor: "#bea76c",
      allowOutsideClick: false,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#658096",
      confirmButtonText: "Submit",
      inputValidator: (value) => {
        if (!value) {
          return "Notes is required!!";
        }
      },
    }).then(async (res) => {
      if (res.isConfirmed) {
        const payload = {
          registrationID: designId,
          approvalOrRejectionFlag: statusFlag,
          agentId: agentInfo?.agentId,
          notes: res.value,
        };
        await dispatch(takeAction(payload)).unwrap();
      }
    });
  };

  const _rsActionState = useSelector(getRegActionState);

  useEffect(() => {
    if (_rsActionState?.apiStatus === "failed") {
      Swal.fire({
        icon: "error",
        title: "Uh ohh!",
        confirmButtonColor: "#8f6d26",
        text: _rsActionState?.message
          ? _rsActionState?.message === "UNAUTHORIZED"
            ? "Session expired. Please login again!!"
            : _rsActionState?.message
          : "Something went wrong. Please try again!!",
        allowOutsideClick: false,
      }).then((res) => {
        if (res.isConfirmed) {
          if (_rsActionState?.message === "UNAUTHORIZED") {
            ls.clear();
            dispatch(revertAll());
            navigate("/login", { replace: true });
          }
        }
      });
    }
    if (_rsActionState?.apiStatus === "success") {
      Swal.fire({
        icon: "success",
        title: "Success",
        confirmButtonColor: "#8f6d26",
        text: "",
        allowOutsideClick: false,
      }).then((res) => {
        if (res.isConfirmed) {
          if (_rsActionState?.message === "UNAUTHORIZED") {
            ls.clear();
            dispatch(revertAll());
            navigate("/login", { replace: true });
          }          
          dispatch(revertAll());
        }
      });
      setRefresh(true)
    }
    return () => {
      setRefresh(false)
    }
  }, [_rsActionState]);

  return (
    <>
      <Paper sx={{ padding: "1rem", margin: "1rem 0" }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              color="primary"
              sx={{ textTransform: "uppercase" }}
            >
              Design Details
            </Typography>
            {_rsViewDesign && _rsViewDesign.data.darekStatus === 0 && (
              <ButtonGroup>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => submitAction(1)}
                >
                  Approve
                </Button>
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={() => submitAction(2)}
                >
                  Reject
                </Button>
              </ButtonGroup>
            )}
          </Grid>
          {Object.entries(thisDesign).map(([key, value]) => (
            <Grid item xs={6} sm={6} md={4} key={key}>
              <List
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    {key}
                  </ListSubheader>
                }
              >
                {typeof value === "object" ? (
                  value &&
                  value !== null &&
                  value.map((item, i) => (
                    <ListItem sx={{ padding: "0 1rem" }} key={i}>
                      <ListItemText primary={item}></ListItemText>
                    </ListItem>
                  ))
                ) : (
                  <ListItem
                    sx={{
                      padding: "0 1rem",
                      width: `${
                        value === 0 || value === 1 || value === 2
                          ? "min-content"
                          : "100%"
                      }`,
                    }}
                  >
                    <ListItemText
                      primary={
                        value === null || value === ""
                          ? "hello"
                          : value === 0
                          ? "Pending"
                          : value === 1
                          ? "Approved"
                          : value === 2
                          ? "Rejected"
                          : value
                      }
                      sx={{
                        color: `${
                          value === 0 || value === 1 || value === 2
                            ? "#ffffff"
                            : ""
                        }`,
                        background: `${
                          value === 0
                            ? "#ed6c02"
                            : value === 1
                            ? "#2e7d32"
                            : value === 2
                            ? "#d32f2f"
                            : ""
                        }`,
                        width: `${
                          value === 0 || value === 1 || value === 2
                            ? "min-content"
                            : "100%"
                        }`,
                        padding: `${
                          value === 0 || value === 1 || value === 2
                            ? ".125rem"
                            : ""
                        }`,
                        borderRadius: `${
                          value === 0 || value === 1 || value === 2
                            ? "100px"
                            : ""
                        }`,
                        textAlign: `${
                          value === 0 || value === 1 || value === 2
                            ? "center"
                            : ""
                        }`,
                        minWidth: "5rem",
                      }}
                    ></ListItemText>
                  </ListItem>
                )}
              </List>
            </Grid>
          ))}
        </Grid>
      </Paper>
      <Paper sx={{ padding: "1rem", margin: "1rem 0" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              color="primary"
              sx={{ textTransform: "uppercase" }}
            >
              Design Files
            </Typography>
            <Divider sx={{ margin: "1rem 0" }} />
          </Grid>
          {_rsViewImages?.data && (
            <>
              {_rsViewImages?.data?.map((item, i) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  sx={{
                    display: "flex",
                    maxHeight: "350px",
                    "& img": { objectFit: "cover" },
                    position: "relative",
                  }}
                  key={i}
                  onMouseEnter={() => {
                    handleShowOverlay(i);
                  }}
                  onMouseLeave={() => handleHideOverlay(i)}
                >
                  <img
                    width="100%"
                    src={`data:application/pdf;base64,${item}`}
                    alt={`designFile_${i}`}
                  />
                  {hoverIndex === i && (
                    <Box
                      sx={{
                        position: "absolute",
                        width: "calc(100% - 16px)",
                        height: "calc(100% - 16px)",
                        background: "#00000050",
                        bottom: "0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        sx={{
                          background: "#bea76c",
                          "&:hover": { background: "#cdbb8e" },
                          borderRadius: "8px",
                        }}
                        size="large"
                        color="white"
                        onClick={() => popupImage(i)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Box>
                  )}
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Paper>
      <Loading
        open={
          _rsViewDesign?.apiStatus === "pending" ||
          _rsViewImages?.apiStatus === "pending"
        }
      />
    </>
  );
};

export default ViewDesign;
