import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { resetPasswordSchema } from "../utils/ValidationSchema";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import { getResetPasswordState, revertAll } from "../AuthenticationSlice";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../AuthenticationApis";
import Loading from "../../../components/Loading";

const ResetPasswordForm = () => {
  const { control, ref, handleSubmit, reset } = useForm({
    mode: "onBlur",
    resolver: yupResolver(resetPasswordSchema),
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //component states
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { agentUUID } = useParams();

  const stateResetPassword = useSelector(getResetPasswordState);

  useEffect(() => {
    if (agentUUID === undefined) {
      Swal.fire({
        icon: "error",
        title: "Uh oh!!",
        text: "Currently we are unable to verify the agent. Please try again later!!",
        confirmButtonColor: "#8f6d26",
      }).then((res) => {
        if (res.isConfirmed) {
          navigate("/account/signin", { replace: true });
        }
      });
    }
  }, [agentUUID]);

  const onSubmit = async (values) => {
    const payload = {
      agentUUID: agentUUID,
      password: values.password,
    };
    await dispatch(resetPassword(payload)).unwrap();
  };

  useEffect(() => {
    if (stateResetPassword?.apiStatus === "failed") {
      Swal.fire({
        icon: "error",
        title: "Uh Oh!!",
        text: "Something went wrong while resetting your password. Please try again later!!",
        confirmButtonColor: "#8f6d26",
      }).then((res) => {
        if (res.isConfirmed) {
          reset();
        }
      });
    }
    if (stateResetPassword && stateResetPassword?.apiStatus === "success") {
      Swal.fire({
        icon: "success",
        title: "Wooh!!",
        text: "Your password is successfully updated!!",
        confirmButtonColor: "#8f6d26",
      })
        .then((res) => {
          if (res.isConfirmed) {
            reset();
          }
        })
        .finally(() => {
          dispatch(revertAll())
          navigate("/account/signin", { replace: true });
          window.location.reload();
        });
    }
  }, [stateResetPassword]);

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth margin="dense">
          <Controller
            control={control}
            ref={ref}
            name="password"
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState?.error}
                helperText={fieldState?.error?.message}
                label="New password"
                size="small"
                variant="outlined"
                color="secondary"
                type={!!showPassword ? "text" : "password"}
                placeholder="********"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex={-1}
                        size="small"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        {!!showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth margin="dense">
          <Controller
            control={control}
            ref={ref}
            name="confirmPassword"
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState?.error}
                helperText={fieldState?.error?.message}
                label="Confirm password"
                size="small"
                variant="outlined"
                color="secondary"
                type={!!showConfirmPassword ? "text" : "password"}
                placeholder="********"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex={-1}
                        size="small"
                        onClick={() => {
                          setShowConfirmPassword(!showConfirmPassword);
                        }}
                      >
                        {!!showConfirmPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth margin="dense">
          <Button variant="contained" type="submit" color="secondary">
            Reset Now
          </Button>
        </FormControl>
      </form>
      <Loading open={stateResetPassword.apiStatus === "pending"}/>
    </Box>
  );
};

export default ResetPasswordForm;
