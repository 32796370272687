import { Grid, Typography } from "@mui/material";
import React from "react";

const Header = ({ image, title }) => {
  return (
    <Grid container spacing={2} sx={{ alignItems: "center" }}>
      <Grid item xs={12} md={2}>
        <img src={image} alt="Darek logo" />
      </Grid>
      <Grid item xs={12} sx={{ margin: "1rem" }}>
        <Typography variant="h5" color="primary">
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Header;
