import { Container, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateToken } from "../UploadDesign/UploadDesignApis";
import { getTokenState } from "../UploadDesign/UploadDesignSlice";
import Swal from "sweetalert2";
import { revertAll } from "../Authentication/AuthenticationSlice";
import { useNavigate } from "react-router-dom";
import SecureLS from "secure-ls";
import Loading from "../../components/Loading";

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ls = new SecureLS({ encodingType: "aes" });

  const _rsTokenState = useSelector(getTokenState);
  useEffect(() => {
    dispatch(generateToken());
  }, []);

  useEffect(() => {
    if (_rsTokenState?.apiStatus === "failed") {
      Swal.fire({
        icon: "error",
        title: "Uh ohh!!",
        text: _rsTokenState?.message
          ? _rsTokenState?.message === "UNAUTHORIZED"
            ? "Session expired. Please login again!!"
            : _rsTokenState?.message
          : "Something went wrong while generating a valid page. Please try again!!",
        confirmButtonColor: "#bea76c",
      }).then((res) => {
        if (res.isConfirmed) {
          if (_rsTokenState?.message === "UNAUTHORIZED") {
            ls.clear();
            dispatch(revertAll());
            navigate("/login", { replace: true });
          }
        }
      });
    }
    if (_rsTokenState?.apiStatus === "success") {
      ls.set("pubToken", _rsTokenState?.data?.token);
    }
  }, [_rsTokenState]);

  return (
    <Paper sx={{ padding: "1rem", margin: "1rem 0" }}>
      <Typography variant="h4" color="primary">
        Hello {props?.user?.agentName}
      </Typography>
      <Typography variant="h6">
        Please click on the available menu to continue to your preferred
        section.
      </Typography>
      <Loading open={_rsTokenState?.apiStatus === "pending"} />
    </Paper>
  );
};

export default Dashboard;
