import { createAction, createSlice } from "@reduxjs/toolkit";
import {
  agentLogin,
  forgotPassword,
  getAgentSecurityQuestions,
  getLoginDetails,
  getSecurityQuestionsList,
  resetPassword,
  sendLoginOTP,
  setSecurityQuestions,
  updateLoginTime,
  updateSecurityQuestions,
  verifyAgentSecurityQuestions,
  verifyLoginOTP,
} from "./AuthenticationApis";
import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: "aes" });
const token = ls.get("fmsToken");

const initialState = {
  loginDetails: {
    apiStatus: "idle",
    statusCode: "",
    error: false,
    message: "",
    attempt: 0,
    data: [],
  },
  agent: {
    apiStatus: "idle",
    statusCode: "",
    error: false,
    message: "",
    data: [],
    isAuthenticated: token && token ? true : false,
  },
  loginTime: {
    apiStatus: "idle",
    statusCode: "",
    error: false,
    message: "",
    data: [],
  },
  sendOtp: {
    apiStatus: "idle",
    statusCode: "",
    error: false,
    message: "",
    data: [],
  },
  verifyOtp: {
    apiStatus: "idle",
    statusCode: "",
    error: false,
    message: "",
    data: [],
  },
  loginTime: {
    apiStatus: "idle",
    statusCode: "",
    error: false,
    message: "",
    data: [],
  },
  listQuestions: {
    apiStatus: "idle",
    statusCode: "",
    error: false,
    message: "",
    data: [],
  },
  setQuestions: {
    apiStatus: "idle",
    statusCode: "",
    error: false,
    message: "",
    data: [],
  },
  updateQuestions: {
    apiStatus: "idle",
    statusCode: "",
    error: false,
    message: "",
    data: [],
  },
  agentSecurityQuestions: {
    apiStatus: "idle",
    statusCode: "",
    error: false,
    message: "",
    data: [],
  },
  agentSecurityAnswer: {
    apiStatus: "idle",
    statusCode: "",
    error: false,
    message: "",
    data: [],
  },
  forgotPassword: {
    apiStatus: "idle",
    statusCode: "",
    error: false,
    message: "",
    data: [],
  },
  resetPassword: {
    apiStatus: "idle",
    statusCode: "",
    error: false,
    message: "",
    data: [],
  },
};

export const revertAll = createAction("REVERT_ALL");

const AuthenticationSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      //===Get login details Api===
      .addCase(getLoginDetails.pending, (state) => {
        state.loginDetails.apiStatus = "pending";
      })
      .addCase(getLoginDetails.rejected, (state, action) => {
        state.loginDetails.apiStatus = "failed";
        state.loginDetails.attempt = state.loginDetails.attempt + 1;
        state.loginDetails.statusCode = action.payload.status;
        state.loginDetails.error = true;
        state.loginDetails.message =
          typeof action.payload.data === "string"
            ? action.payload.data
            : action.payload.data.message;
        state.loginDetails.data = action.payload.data;
      })
      .addCase(getLoginDetails.fulfilled, (state, action) => {
        console.log(action.payload);
        state.loginDetails.apiStatus = "success";
        state.loginDetails.statusCode = action.payload.status;
        state.loginDetails.data = action.payload.data;
      })
      //===agent login Api===
      .addCase(agentLogin.pending, (state) => {
        state.agent.apiStatus = "pending";
      })
      .addCase(agentLogin.rejected, (state, action) => {
        state.agent.apiStatus = "failed";
        state.agent.statusCode = action.payload.status;
        state.agent.error = true;
        state.agent.message =
          typeof action.payload.data === "string"
            ? action.payload.data
            : action.payload.data.message;
        state.agent.isAuthenticated = false;
      })
      .addCase(agentLogin.fulfilled, (state, action) => {
        state.agent.apiStatus = "success";
        state.agent.error = false;
        state.agent.data = action.payload.data;
        state.agent.isAuthenticated = true;
      })
      //===Send OTP Api ===
      .addCase(sendLoginOTP.pending, (state) => {
        state.sendOtp.apiStatus = "pending";
      })
      .addCase(sendLoginOTP.rejected, (state, action) => {
        state.sendOtp.apiStatus = "failed";
        state.sendOtp.statusCode = action.payload.status;
        state.sendOtp.error = true;
        state.sendOtp.message =
          typeof action.payload.data === "string"
            ? action.payload.data
            : action.payload.data.message;
      })
      .addCase(sendLoginOTP.fulfilled, (state, action) => {
        state.sendOtp.apiStatus = "success";
        state.sendOtp.error = false;
        state.sendOtp.data = action.payload.data;
      })
      //===Verify OTP Api =====
      .addCase(verifyLoginOTP.pending, (state) => {
        state.verifyOtp.apiStatus = "pending";
      })
      .addCase(verifyLoginOTP.rejected, (state, action) => {
        state.verifyOtp.apiStatus = "failed";
        state.verifyOtp.statusCode = action.payload.status;
        state.verifyOtp.error = true;
        state.verifyOtp.message =
          typeof action.payload.data === "string"
            ? action.payload.data
            : action.payload.data.message;
      })
      .addCase(verifyLoginOTP.fulfilled, (state, action) => {
        state.verifyOtp.apiStatus = "success";
        state.verifyOtp.error = false;
        state.verifyOtp.data = action.payload.data;
      })
      //===Update login time Api===
      .addCase(updateLoginTime.pending, (state) => {
        state.loginTime.apiStatus = "pending";
      })
      .addCase(updateLoginTime.rejected, (state, action) => {
        state.loginTime.apiStatus = "failed";
        state.loginTime.statusCode = action.payload.status;
        state.loginTime.error = true;
        state.loginTime.message =
          typeof action.payload.data === "string"
            ? action.payload.data
            : action.payload.data.message;
      })
      .addCase(updateLoginTime.fulfilled, (state, action) => {
        state.loginTime.apiStatus = "success";
        state.loginTime.error = false;
        state.loginTime.message = "";
        state.loginTime.data = action.payload.data;
      })
      //===get security questions api===
      .addCase(getSecurityQuestionsList.pending, (state) => {
        state.listQuestions.apiStatus = "pending";
      })
      .addCase(getSecurityQuestionsList.rejected, (state, action) => {
        state.listQuestions.apiStatus = "failed";
        state.listQuestions.statusCode = action.payload.status;
        state.listQuestions.error = true;
        state.listQuestions.message =
          typeof action.payload.data === "string"
            ? action.payload.data
            : action.payload.data.message;
      })
      .addCase(getSecurityQuestionsList.fulfilled, (state, action) => {
        state.listQuestions.apiStatus = "success";
        state.listQuestions.error = false;
        state.listQuestions.message = "";
        state.listQuestions.data = action.payload.data;
      })
      //===set security questions api===
      .addCase(setSecurityQuestions.pending, (state) => {
        state.setQuestions.apiStatus = "pending";
      })
      .addCase(setSecurityQuestions.rejected, (state, action) => {
        state.setQuestions.apiStatus = "failed";
        state.setQuestions.statusCode = action.payload.status;
        state.setQuestions.error = true;
        state.setQuestions.message =
          typeof action.payload.data === "string"
            ? action.payload.data
            : action.payload.data.message;
      })
      .addCase(setSecurityQuestions.fulfilled, (state, action) => {
        state.setQuestions.apiStatus = "success";
        state.setQuestions.error = false;
        state.setQuestions.message = "";
        state.setQuestions.data = action.payload.data;
      })
      //===update security questions api===
      .addCase(updateSecurityQuestions.pending, (state) => {
        state.updateQuestions.apiStatus = "pending";
      })
      .addCase(updateSecurityQuestions.rejected, (state, action) => {
        state.updateQuestions.apiStatus = "failed";
        state.updateQuestions.statusCode = action.payload.status;
        state.updateQuestions.error = true;
        state.updateQuestions.message =
          typeof action.payload.data === "string"
            ? action.payload.data
            : action.payload.data.message;
      })
      .addCase(updateSecurityQuestions.fulfilled, (state, action) => {
        state.updateQuestions.apiStatus = "success";
        state.updateQuestions.error = false;
        state.updateQuestions.message = "";
        state.updateQuestions.data = action.payload.data;
      })
      //===get agent security questions Api===
      .addCase(getAgentSecurityQuestions.pending, (state) => {
        state.agentSecurityQuestions.apiStatus = "pending";
      })
      .addCase(getAgentSecurityQuestions.rejected, (state, action) => {
        state.agentSecurityQuestions.apiStatus = "failed";
        state.agentSecurityQuestions.statusCode = action?.payload?.status;
        state.agentSecurityQuestions.error = true;
        state.agentSecurityQuestions.message =
          typeof action?.payload?.data === "string"
            ? action?.payload?.data
            : action?.payload?.data?.message;
      })
      .addCase(getAgentSecurityQuestions.fulfilled, (state, action) => {
        state.agentSecurityQuestions.apiStatus = "success";
        state.agentSecurityQuestions.data = action?.payload?.data;
      })
      //===Verify agent security questions Api===
      .addCase(verifyAgentSecurityQuestions.pending, (state) => {
        state.agentSecurityAnswer.apiStatus = "pending";
      })
      .addCase(verifyAgentSecurityQuestions.rejected, (state, action) => {
        state.agentSecurityAnswer.apiStatus = "failed";
        state.agentSecurityAnswer.statusCode = action.payload.status;
        state.agentSecurityAnswer.error = true;
        state.agentSecurityAnswer.message =
          typeof action.payload.data === "string"
            ? action.payload.data
            : action.payload.data.message;
      })
      .addCase(verifyAgentSecurityQuestions.fulfilled, (state, action) => {
        state.agentSecurityAnswer.apiStatus = "success";
        state.agentSecurityAnswer.error = false;
        state.agentSecurityAnswer.data = action.payload.data;
      })
      //===forgot password Api===
      .addCase(forgotPassword.pending, (state) => {
        state.forgotPassword.apiStatus = "pending";
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.forgotPassword.apiStatus = "failed";
        state.forgotPassword.statusCode = action.payload.status;
        state.forgotPassword.error = true;
        state.forgotPassword.message =
          typeof action.payload.data === "string"
            ? action.payload.data
            : action.payload.data.message;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.forgotPassword.apiStatus = "success";
        state.forgotPassword.statusCode = action.payload.status;
        state.forgotPassword.error = false;
        state.forgotPassword.data = action.payload.data;
      })
      //===reset password Api===
      .addCase(resetPassword.pending, (state) => {
        state.resetPassword.apiStatus = "pending";
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.resetPassword.apiStatus = "failed";
        state.resetPassword.statusCode = action.payload.status;
        state.resetPassword.error = true;
        state.resetPassword.message =
          typeof action.payload.data === "string"
            ? action.payload.data
            : action.payload.data.message;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.resetPassword.apiStatus = "success";
        state.resetPassword.statusCode = action.payload.status;
        state.resetPassword.error = false;
        state.resetPassword.data = action.payload.data;
      });
  },
});

export const getLoginDetailsState = (state) => state.auth.loginDetails;
export const getAgentState = (state) => state.auth.agent;
export const getSendOtpState = (state) => state.auth.sendOtp;
export const getVerifyOtpState = (state) => state.auth.verifyOtp;
export const getloginTimeState = (state) => state.auth.loginTime;

export const getListQuestionsState = (state) => state.auth.listQuestions;
export const getSetQuestionsState = (state) => state.auth.setQuestions;
export const getUpdateQuestionsState = (state) => state.auth.updateQuestions;
export const getAgentSecurityQuestionsState = (state) =>
  state.auth.agentSecurityQuestions;
export const getAgentSecurityAnswerState = (state) =>
  state.auth.agentSecurityAnswer;
export const getForgotPasswordState = (state) => state.auth.forgotPassword;
export const getResetPasswordState = (state) => state.auth.resetPassword;
export const getIsAuthenticated = (state) => state.auth.agent.isAuthenticated;

export default AuthenticationSlice.reducer;
