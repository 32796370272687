import React from "react";
import FormCard from "../../components/FormCard";
import VerifySecurityQuestionForm from "./forms/VerifySecurityQuestionForm";

const VerifySecurityQuestion = () => {
  return (
    <FormCard
      title="Answer Security Question"
      form={<VerifySecurityQuestionForm />}
      gridWidthMd={8}
      gridWidthSm={8}
      gridWidthLg={5}
    />
  );
};

export default VerifySecurityQuestion;
