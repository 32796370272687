import { createAsyncThunk } from "@reduxjs/toolkit";
import { makeFmsRequest, makePubRequest } from "../../app/axios";

//generate Token
export const generateToken = createAsyncThunk(
  "uploadDesign/generateToken",
  async (_, { rejectWithValue }) => {
    try {
      const res = await makePubRequest.get(`/Token`);
      return res;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response);
    }
  }
);
//submit data
export const submitData = createAsyncThunk(
  "uploadDesign/submitData",
  async (data, { rejectWithValue }) => {
    try {
      const res = await makePubRequest.post(`/darek/uploadImage`, data);
      return res;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response);
    }
  }
);
//save document data to db
export const saveDocDataDb = createAsyncThunk(
  "uploadDesign/saveDocDataDb",
  async (data, { rejectWithValue }) => {
    try {
      const res = await makePubRequest.post(`/docUpload`, data);
      return res;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response);
    }
  }
);
//upload docs to s3
export const uploadDocsToS3Api = createAsyncThunk(
  "uploadDesign/uploadDocsToS3Api",
  async (data, { rejectWithValue }) => {
    try {
      const res = await makePubRequest.post(`/docUpload/S3`, data);
      return res;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response);
    }
  }
);

//deactivate registration
export const deactivateRegistration = createAsyncThunk(
  "uploadDesign/deactivateRegistration",
  async (data, { rejectWithValue }) => {
    try {
      const res = await makePubRequest.post(`/darek/deactivate`, data);
      return res;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response);
    }
  }
);

//get designs
export const listDesigns = createAsyncThunk(
  "uploadDesign/listDesigns",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await makePubRequest.post(
        `/darek/filter?limit=${payload.limit}&offset=${payload.offset}`,
        payload.data
      );
      return res;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response);
    }
  }
);

//view designs
export const viewDesigns = createAsyncThunk(
  "uploadDesign/viewDesigns",
  async (data, { rejectWithValue }) => {
    try {
      const res = await makePubRequest.post(`/darek/view`, data);
      return res;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response);
    }
  }
);

//download images
export const downloadimages = createAsyncThunk(
  "uploadDesign/downloadimages",
  async (data, { rejectWithValue }) => {
    try {
      const res = await makePubRequest.post(`/event/multipledocs`, data);
      return res;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response);
    }
  }
);

//download images
export const takeAction = createAsyncThunk(
  "uploadDesign/takeAction",
  async (data, { rejectWithValue }) => {
    try {
      const res = await makePubRequest.post(`/darek/approveReject`, data);
      return res;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response);
    }
  }
);
