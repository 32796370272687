import React from "react";
import FormCard from "../../components/FormCard";
import logo from "../../assets/new-logo.svg";
import ResetPasswordForm from "./forms/ResetPasswordForm";
const ResetPassword = () => {
  return (
    <FormCard
      logo={logo}
      title="Reset Password"
      form={<ResetPasswordForm />}
      gridWidthMd={6}
      gridWidthSm={6}
      gridWidthLg={3}
    />
  );
};

export default ResetPassword;
