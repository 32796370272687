import React from "react";
import FormCard from "../../components/FormCard";
import logo from '../../assets/new-logo.svg'
import ForgotPasswordForm from "./forms/ForgotPasswordForm";

const ForgotPassword = () => {
  return (
    <FormCard
      logo={logo}
      title="Forgot Password"
      form={<ForgotPasswordForm />}
      gridWidthMd={6}
      gridWidthSm={6}
      gridWidthLg={3}
    />
  );
};

export default ForgotPassword;
