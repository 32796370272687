import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Box, Button, FormControl, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../AuthenticationApis";
import { getForgotPasswordState } from "../AuthenticationSlice";
import { forgotPasswordSchema } from "../utils/ValidationSchema";
import Loading from "../../../components/Loading";

const ForgotPasswordForm = () => {
  const { control, ref, handleSubmit, reset } = useForm({
    mode: "onBlur",
    resolver: yupResolver(forgotPasswordSchema),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const forgotPasswordState = useSelector(getForgotPasswordState);
  const [errorAlert, setErrorAlert] = useState();
  const [userData, setUserData] = useState('')

  const onSubmit = async (values) => {
    setErrorAlert("");
    const payload = {
      emailId: values.emailId,
      mobile: values.mobile,
    };
    setUserData(payload)
    await dispatch(forgotPassword(payload)).unwrap();
  };

  useEffect(() => {
    if (forgotPasswordState?.apiStatus === "failed") {
      setErrorAlert(
        forgotPasswordState?.message
          ? forgotPasswordState?.message
          : "Something went wrong while generating an OTP. Please try again later!!"
      );
    }
    if (forgotPasswordState?.apiStatus === "success") {
      navigate("/agent/otp", {
        replace: true,
        state: { agentUUID: forgotPasswordState.data.agentUUID, userData: userData },
      });
    }
  }, [forgotPasswordState]);

  const goBack = () => {
    navigate(-1);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        {errorAlert && (
          <Alert severity="error" sx={{ marginBottom: "1rem" }}>
            {errorAlert}
          </Alert>
        )}
        <FormControl fullWidth margin="dense">
          <Controller
            name="emailId"
            control={control}
            ref={ref}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState?.error}
                helperText={fieldState?.error?.message}
                label="Email id"
                size="small"
                variant="outlined"
                color="secondary"
                type="text"
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth margin="dense">
          <Controller
            name="mobile"
            control={control}
            ref={ref}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState?.error}
                helperText={fieldState?.error?.message}
                label="Mobile number"
                size="small"
                variant="outlined"
                color="secondary"
                type="text"
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth margin="dense">
          <Button variant="contained" color="secondary" type="submit">
            Get OTP
          </Button>
        </FormControl>
        <FormControl fullWidth margin="dense">
          <Button
            variant="outlined"
            color="secondary"
            type="button"
            onClick={goBack}
          >
            Cancel
          </Button>
        </FormControl>
      </form>
      <Loading open={forgotPasswordState.apiStatus === "pending"} />
    </Box>
  );
};

export default ForgotPasswordForm;
