import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, FormControl, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getSecurityQuestionsList } from "../AuthenticationApis";
import { getListQuestionsState } from "../AuthenticationSlice";
import { securityQuestionsSchema } from "../utils/ValidationSchema";
import Loading from "../../../components/Loading";

const SecurityQuestionsForm = () => {
  const { control, ref, handleSubmit, reset } = useForm({
    mode: "onBlur",
    resolver: yupResolver(securityQuestionsSchema),
  });

  const dispatch = useDispatch();

  const listQuestionState = useSelector(getListQuestionsState);
  const [errorAlert, setErrorAlert] = useState();

  useEffect(() => {
    const getQuestionsList = async () => {
      await dispatch(getSecurityQuestionsList()).unwrap();
    };
    getQuestionsList();
  }, []);

  useEffect(() => {
    if (listQuestionState?.apiStatus === "failed") {
      setErrorAlert(
        listQuestionState?.message
          ? listQuestionState?.message
          : "Something went wrong while loading security questions. Please try again later!!"
      );
    }
    if (listQuestionState?.apiStatus === "success") {
    //   listQuestionState?.data.map((w) => {
    //     console.log(w.questions);
    //   });
    }
  }, [listQuestionState]);

  const onSubmit = (values) => {
    console.log(values)
  };
  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        {listQuestionState?.data.map((question, i) => (
          <Box key={question.id}>
            <FormControl fullWidth margin="dense">
              <Controller
                control={control}
                ref={ref}
                defaultValue={question.questions}
                name={`securityQuestion${i}`}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    variant="outlined"
                    size="small"
                    label="Question"
                    disabled
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth margin="dense">
              <Controller
                control={control}
                ref={ref}
                defaultValue=""
                name={`securityAnswer${i}`}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    variant="outlined"
                    size="small"
                    label="Answer"
                  />
                )}
              />
            </FormControl>
          </Box>
        ))}
        <FormControl fullWidth margin="dense">
          <Button variant="contained" color="secondary" type="submit">submit</Button>
        </FormControl>
      </form>
      <Loading open={listQuestionState.apiStatus === "pending"} />
    </Box>
  );
};

export default SecurityQuestionsForm;
