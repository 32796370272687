import { createAsyncThunk } from "@reduxjs/toolkit";
import { makeFmsRequest } from "../../app/axios";

//get login details {agentUUID, flag1, flag2, flag3, flag4} and redirect/handle user accordingly
export const getLoginDetails = createAsyncThunk(
  "/auth/getLoginDetails",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const res = await makeFmsRequest.post("/login/agent/loginDetails", data);
      return res;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response);
    }
  }
);
//login
export const agentLogin = createAsyncThunk(
  "/auth/agentLogin",
  async (data, { rejectWithValue }) => {
    try {
      const res = await makeFmsRequest.post("/login/agent", data);
      return res;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response);
    }
  }
);

//send OTP
export const sendLoginOTP = createAsyncThunk(
  "/auth/sendLoginOTP",
  async (_, { rejectWithValue }) => {
    try {
      const res = await makeFmsRequest.post("/login/agent/sendOtp");
      return res;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response);
    }
  }
);

//verify OTP
export const verifyLoginOTP = createAsyncThunk(
  "/auth/verifyLoginOTP",
  async (data, { rejectWithValue }) => {
    try {
      const res = await makeFmsRequest.post("/verifyOtp/agent", data);
      return res;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response);
    }
  }
);

//Update login time
export const updateLoginTime = createAsyncThunk(
  "/auth/updateLoginTime",
  async (data, { rejectWithValue }) => {
    try {
      const res = await makeFmsRequest.post("/login/agentDetails", data);
      return res;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response);
    }
  }
);

//get security questions list
export const getSecurityQuestionsList = createAsyncThunk(
  "/auth/getSecurityQuestionsList",
  async (_, { rejectWithValue }) => {
    try {
      const res = await makeFmsRequest.get("/agent/securityQuestions");
      return res;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response);
    }
  }
);

//set security questions
export const setSecurityQuestions = createAsyncThunk(
  "/auth/setSecurityQuestions",
  async (_, { rejectWithValue }) => {
    try {
      const res = await makeFmsRequest.post("/agent/securityAnswers");
      return res;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response);
    }
  }
);

//update security questions
export const updateSecurityQuestions = createAsyncThunk(
  "/auth/updateSecurityQuestions",
  async (_, { rejectWithValue }) => {
    try {
      const res = await makeFmsRequest.post("/agent/updateSecurityQuestions");
      return res;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response);
    }
  }
);

//get agent security questions
export const getAgentSecurityQuestions = createAsyncThunk(
  "/auth/getAgentSecurityQuestions",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const res = await makeFmsRequest.post("/getSecurityQuestion", data);
      return res;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response);
    }
  }
);

//verify security questions
export const verifyAgentSecurityQuestions = createAsyncThunk(
  "/auth/verifyAgentSecurityQuestions",
  async (data, { rejectWithValue }) => {
    try {
      const res = await makeFmsRequest.post("/checkSecurityAnswer", data);
      return res;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response);
    }
  }
);

//forgot password
export const forgotPassword = createAsyncThunk(
  "/auth/forgotPassword",
  async (data, { rejectWithValue }) => {
    try {
      const res = await makeFmsRequest.post("/forgotPassword/agent", data);
      return res;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response);
    }
  }
);

//reset password
export const resetPassword = createAsyncThunk(
  "/auth/resetPassword",
  async (data, { rejectWithValue }) => {
    try {
      const res = await makeFmsRequest.post("/resetPassword/agent", data);
      return res;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response);
    }
  }
);
