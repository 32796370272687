import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  ImageList,
  ImageListItem,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import attachIcon from "../assets/attach-icon.svg";
import { Add, Close } from "@mui/icons-material";
import docerror from "../assets/docerror-icon.png";

const FileUpload = React.forwardRef(
  ({ isMultiple, label, error, ...props }, ref) => {
    const fileInput = useRef();
    // const { control, watch } = useForm({
    //   mode: "onBlur",
    // });

    const [files, setFiles] = useState([]);

    const handleFileChange = (e) => {
      const selectedFiles = Array.from(e.target.files);
      props.onChange(selectedFiles);
      setFiles(selectedFiles);
    };

    const removeItem = (item) => {
      const filteredArr = files.filter((f) => f !== files[item]);
      props.onChange(filteredArr);
      setFiles(filteredArr);
    };

    const addMoreFiles = (e) => {
      const currentArr = [...files];
      const newArr = currentArr.push(Array.from(e.target.files));
      props.onChange(newArr);
      setFiles(newArr);
    };

    useEffect(() => {
      console.log(props);
      console.log(error.designDocuments);
    }, [props]);

    useEffect(() => {
      props.onChange(files);
    }, [files]);

    return (
      <>
        <FormLabel sx={{ marginBottom: "1rem" }}>{label}</FormLabel>
        <Box
          sx={{
            position: "relative",
            cursor: "pointer",
          }}
        >
          <label htmlFor="fileUpload">
            <TextField
              fullWidth
              ref={fileInput}
              type="file"
              id="fileUpload"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                multiple: isMultiple,
                id: "fileUpload",
              }}
              InputProps={{
                sx: {
                  border: "none",
                  display: "none",
                },
              }}
              sx={{
                height: "150px",
                border: "2px dashed #ddd",
                borderRadius: "8px",
              }}
              onChange={handleFileChange}
              value={undefined}
            />

            <Button
              onClick={() => {
                fileInput.current.click();
              }}
              disableRipple
              variant="text"
              size="small"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                "& img": {
                  width: { xs: "25%", sm: "15%", md: "20%" },
                },
                "&:hover": {
                  background: "none",
                },
              }}
            >
              <img src={attachIcon} alt="attach icon" />
              <Typography variant="caption" sx={{ marginTop: "1rem" }}>
                {!!isMultiple ? "Attach multiple files" : "Attach single file"}
              </Typography>
            </Button>
          </label>
        </Box>
        <Box>
          <ImageList cols={4} rowHeight={250} gap={16}>
            {files.length > 0 &&
              files.map((f, i) => (
                <ImageListItem
                  key={i}
                  sx={{
                    border: !!error?.designDocuments ? "1px solid red" : "none",
                    backgroundColor: "#f5f5f5",
                    position: "relative",
                    "& img": {
                      objectFit: "contain !important",
                      maxHeight: "250px",
                    },
                  }}
                >
                  <Box
                    sx={{ position: "absolute", right: 0, padding: ".5rem" }}
                  >
                    <IconButton
                      size="small"
                      sx={{
                        color: "#d32f2f",
                        background: "#fff",
                        "&:hover": { background: "#ebebeb" },
                      }}
                      onClick={() => removeItem(i)}
                    >
                      <Close />
                    </IconButton>
                  </Box>
                  {f.type === "image/jpg" ||
                  f.type === "image/jpeg" ||
                  f.type === "image/png" ? (
                    <img
                      src={`${URL.createObjectURL(f)}`}
                      srcSet={`${URL.createObjectURL(f)}`}
                      loading="lazy"
                    />
                  ) : (
                    <img
                      width="100%"
                      src={docerror}
                      loading="lazy"
                      srcSet={docerror}
                    />
                  )}
                </ImageListItem>
              ))}
          </ImageList>
        </Box>
      </>
    );
  }
);

export default FileUpload;
