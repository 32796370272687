import * as Yup from "yup";

//yup validation schema for login
export const loginSchema = Yup.object().shape({
  agentName: Yup.string().required("Username is required!!"),
  password: Yup.string()
    .required("Password is required!!")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must be 8 characters long, contain at least one uppercase letter, one number, and one special character!!"
    ),
});

//yup validation schema for otp form
export const otpSchema = Yup.object().shape({
  otp: Yup.string().required("OTP is required!!"),
});

//yup validation schema for forgot password form
export const forgotPasswordSchema = Yup.object().shape({
  emailId: Yup.string().required("Email id is required!!").email('Invalid email id!!'),
  mobile: Yup.string().required("Mobile number is required!!"),
});

//yup validation schema for reset password form
export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("New password is required!!")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must be 8 characters long, contain at least one uppercase letter, one number, and one special character!!"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match!!")
    .required("Confirm password is required!!")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must be 8 characters long, contain at least one uppercase letter, one number, and one special character!!"
    ),
});

//yup validation schema for verify security question form
export const verifySecurityQuestionSchema = Yup.object().shape({
  agentSecurityQuestion: Yup.string().required("Please select a question!!"),
  agentSecurityAnswer: Yup.string().required("Answer is required!!"),
});

//yup validation schema for verify security question form
export const securityQuestionsSchema = Yup.object().shape({
  agentSecurityQuestion: Yup.string().required("Please select a question!!"),
  agentSecurityAnswer: Yup.string().required("Answer is required!!"),
});
