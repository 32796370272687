import { Box, Button, CircularProgress, Fade, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTimer } from "reactjs-countdown-hook";
import RefreshIcon from "@mui/icons-material/Refresh";

const CountDownTimer = (props) => {
  const {
    isActive,
    counter,
    seconds,
    minutes,
    hours,
    days,
    pause,
    resume,
    reset,
  } = useTimer(props.time, props.handleTimerFinish);

  useEffect(() => {
    if (!!props.isReset) {
      reset();
    }
  }, [props]);

  return (
    <Box>
      {!props.isOverlay && props.format === "days" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
            flexWrap: "wrap",
          }}
        >
          {props.timerFor && (
            <Typography variant="body1">{props.timerFor}</Typography>
          )}
          <Typography variant="body1">
            &nbsp;
            {`${days ? days : "00"} days : ${hours ? hours : "00"} hours : ${
              minutes ? minutes : "00"
            }  : ${seconds ? seconds : "00"} `}
            &nbsp;
          </Typography>
        </Box>
      )}
      {!props.isOverlay && props.format === "hours" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
            flexWrap: "wrap",
          }}
        >
          {props.timerFor && (
            <Typography variant="body1">{props.timerFor}</Typography>
          )}
          <Typography variant="body1">
            &nbsp;
            {`${hours ? hours : "00"} : ${minutes ? minutes : "00"}  : ${
              seconds ? seconds : "00"
            } `}
            &nbsp;
          </Typography>
        </Box>
      )}
      {!props.isOverlay && props.format === "minutes" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
            flexWrap: "wrap",
          }}
        >
          {props.timerFor && (
            <Typography variant="body1">{props.timerFor}</Typography>
          )}
          <Typography variant="body1">
            &nbsp;
            {`${minutes ? minutes : "00"}  : ${seconds ? seconds : "00"} `}
            &nbsp;
          </Typography>
        </Box>
      )}
      {!props.isOverlay && props.format === "seconds" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
            flexWrap: "wrap",
          }}
        >
          {props.timerFor && (
            <Typography variant="body1">{props.timerFor}</Typography>
          )}
          <Typography variant="body1">
            &nbsp;
            {`${seconds ? seconds : "00"} `}&nbsp;
          </Typography>
        </Box>
      )}
      {/* {
        <Fade in={!!props.isOverlay} timeout={500}>
          <Box
            sx={{
              position: "absolute",
              top: "54%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "300px",
              height: "320px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                reset();
                props.newQr();
              }}
              startIcon={
                props.isLoading ? (
                  <CircularProgress
                    sx={{ color: (theme) => theme.palette.white.main }}
                    size={20}
                  />
                ) : (
                  <RefreshIcon />
                )
              }
            >
              Get new QR
            </Button>
          </Box>
        </Fade>
      } */}
    </Box>
  );
};

export default CountDownTimer;
