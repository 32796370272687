import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";

const FormCard = (props) => {
  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        item
        xs={12}
        md={props?.gridWidthMd}
        sm={props?.gridWidthSm}
        lg={props?.gridWidthLg}
      >
        <Card elevation={12} sx={{ borderRadius: "1rem" }}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              // minHeight: "410px",
            }}
          >
            <Box>
              {props?.logo && (
                <Box
                  sx={{
                    padding: "0 2rem",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "1.5rem",
                  }}
                >
                  <img width="70%" src={props?.logo} alt="logo" />
                </Box>
              )}
              {props.title && (
                <Typography
                  variant="h5"
                  color="secondary"
                  sx={{
                    textTransform: "uppercase",
                    margin: "1rem",
                    textAlign: "center",
                  }}
                >
                  {props.title}
                </Typography>
              )}
            </Box>
            <Box>{props.form && props.form}</Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default FormCard;
