import axios from "axios";
import SecureLS from "secure-ls";

let API_ENDPOINT = "";
let API_ENDPOINT_FMS = "";
if (process.env.NODE_ENV === "development") {
  API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT_PRD;
  API_ENDPOINT_FMS = process.env.REACT_APP_API_ENDPOINT_FMS_PRD;
} else {
  //staging

  // API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT_STG;
  // API_ENDPOINT_FMS = process.env.REACT_APP_API_ENDPOINT_FMS_TST;

  //production

  API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT_PRD;
  API_ENDPOINT_FMS = process.env.REACT_APP_API_ENDPOINT_FMS_PRD;
}

const ls = new SecureLS({ encodingType: "aes" });

export const makeFmsRequest = axios.create({
  baseURL: API_ENDPOINT_FMS,
});

export const makePubRequest = axios.create({
  baseURL: API_ENDPOINT,
});

makePubRequest.interceptors.request.use(
  (config) => {
    const tokenPub = ls.get("pubToken");
    if (tokenPub) {
      config.headers.Authorization = `Bearer ${tokenPub}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

makeFmsRequest.interceptors.request.use(
  (config) => {
    const tokenFms = ls.get("fmsToken");
    if (tokenFms) {
      config.headers.Authorization = `Bearer ${tokenFms}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
