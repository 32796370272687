import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  MenuItem,
  TextField,
  Box,
  Alert,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  getAgentSecurityQuestions,
  verifyAgentSecurityQuestions,
} from "../AuthenticationApis";
import {
  getAgentSecurityQuestionsState,
  getLoginDetailsState,
  getAgentSecurityAnswerState,
} from "../AuthenticationSlice";
import revertAll from "../AuthenticationSlice";
import { verifySecurityQuestionSchema } from "../utils/ValidationSchema";
import Loading from "../../../components/Loading";

const VerifySecurityQuestionForm = (props) => {
  //define and configure useform hook of react-hook-form
  const { control, ref, handleSubmit, reset } = useForm({
    mode: "onBlur",
    resolver: yupResolver(verifySecurityQuestionSchema),
  });

  //define useDispatch hook
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //component states
  const [alertMessage, setAlertMessage] = useState("");

  //redux store states
  //get agent security questions
  const stateAgentSecirityQuestions = useSelector(
    getAgentSecurityQuestionsState
  );
  //login details state value to get agent uuid
  const stateLoginDetails = useSelector(getLoginDetailsState);

  //verify agent security answer state
  const isVerifiedSecurityAnswer = useSelector(getAgentSecurityAnswerState);

  //get agent security questions
  useEffect(() => {
    const getSecurityQuestions = async () => {
      const data = {
        agentUUID: stateLoginDetails?.data?.agentUUID,
      };
      await dispatch(getAgentSecurityQuestions(data)).unwrap();
    };
    getSecurityQuestions();
    console.log(props);
  }, []);

  //useeffect hook to handle error case load questions
  useEffect(() => {
    if (stateAgentSecirityQuestions.apiStatus === "failed") {
      setAlertMessage(
        "Something went wrong while loading the security questions. Please try again later!!"
      );
    }
  }, [stateAgentSecirityQuestions]);

  const onSubmit = async (values) => {
    setAlertMessage("");
    const payload = {
      agentUUID: stateLoginDetails?.data?.agentUUID,
      answer: values.agentSecurityAnswer,
      questionID: values.agentSecurityQuestion,
    };
    await dispatch(verifyAgentSecurityQuestions(payload)).unwrap();
  };

  //useeffect hook to handle verify agent answer
  useEffect(() => {
    if (isVerifiedSecurityAnswer.apiStatus === "failed") {
      if (isVerifiedSecurityAnswer.statusCode !== 423) {
        setAlertMessage(
          "Something went wrong while verifying your security answer. Please try again later!!"
        );
      } else {
        Swal.fire({
          icon: "warning",
          title: "Blocked",
          text: `${isVerifiedSecurityAnswer.message}`,
          confirmButtonColor: "#8f6d26",
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(revertAll);
            navigate("/account/signin", { replace: true });
            window.location.reload();
          }
        });
      }
    } else if (isVerifiedSecurityAnswer.apiStatus === "success") {
      navigate(`/agent/resetPassword/${stateLoginDetails?.data?.agentUUID}`, {
        replace: true,
      });
    }
  }, [isVerifiedSecurityAnswer]);

  return (
    <Box>
      {alertMessage && (
        <Alert severity="error" sx={{ marginBottom: "1rem" }}>
          {alertMessage}
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth margin="dense">
          <Controller
            name="agentSecurityQuestion"
            control={control}
            ref={ref}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState?.error}
                helperText={fieldState?.error?.message}
                label="Select question"
                size="small"
                variant="outlined"
                select
                color="secondary"
                disabled={!!stateAgentSecirityQuestions.error}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {stateAgentSecirityQuestions.apiStatus === "pending" && (
                        <CircularProgress size={16} />
                      )}
                    </InputAdornment>
                  ),
                }}
              >
                {stateAgentSecirityQuestions?.data.map((question) => (
                  <MenuItem
                    key={question.questionId}
                    value={question.questionId}
                  >
                    {question.questions}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </FormControl>
        <FormControl fullWidth margin="dense">
          <Controller
            name="agentSecurityAnswer"
            control={control}
            ref={ref}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState?.error}
                helperText={fieldState?.error?.message}
                label="Answer"
                size="small"
                type="text"
                variant="outlined"
                color="secondary"
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth margin="dense">
          <Button variant="contained" type="submit" color="secondary">
            Submit
          </Button>
        </FormControl>
      </form>
      <Loading open={stateAgentSecirityQuestions.apiStatus === "pending"} />
    </Box>
  );
};

export default VerifySecurityQuestionForm;
