import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  Link,
  TextField,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { loginSchema } from "../utils/ValidationSchema";
import {
  getLoginDetails,
  agentLogin,
  updateLoginTime,
} from "../AuthenticationApis";
import { getAgentState, getLoginDetailsState } from "../AuthenticationSlice";
import { useNavigate } from "react-router-dom";
import SecureLS from "secure-ls";
import Swal from "sweetalert2";
import Loading from "../../../components/Loading";

const LoginForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ls = new SecureLS({ encodingType: "aes" });
  //component states
  const [showPassword, setShowPassword] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [userCredentials, setUserCredentials] = useState("");

  //redux states

  const stateLoginDetails = useSelector(getLoginDetailsState);
  const stateAgent = useSelector(getAgentState);

  //define and configure useForm hook
  const { control, ref, handleSubmit, reset } = useForm({
    mode: "onBlur",
    resolver: yupResolver(loginSchema),
  });

  //handles form submission
  const onSubmit = async (values) => {
    const data = {
      agentName: values.agentName,
      password: values.password,
    };
    setUserCredentials(data);
    await dispatch(getLoginDetails(data))
      .unwrap()
      .then(() => {
        // setAlertMessage("");
        console.log(stateLoginDetails);
      });
  };

  useEffect(() => {
    const isOtpSent = stateLoginDetails.data.flag2;
    const isBlocked = stateLoginDetails.data.flag3;
    if (stateLoginDetails.apiStatus === "success") {
      if (isBlocked === 1 || isBlocked === 2) {
        //account blocked due to incorrect security answer
        setAlertMessage(
          "Your account has been blocked. Please contact fazaa IT department!!"
        );
      } else {
        if (isOtpSent === 1) {
          navigate("/agent/otp", {
            replace: true,
            state: { credentials: userCredentials },
          });
        } else if (isOtpSent === 2) {
          setAlertMessage(
            "Something went wrong while sending OTP. Please try again later!!"
          );
        } else {
          dispatch(agentLogin(userCredentials)).unwrap();
        }
      }
    }
    if (stateLoginDetails.apiStatus === "failed") {
      console.log(stateLoginDetails.apiStatus);
      if (stateLoginDetails?.statusCode === 422) {
        setAlertMessage(
          "Your account has been blocked. Please contact fazaa IT department!!"
        );
      }
      if (stateLoginDetails?.statusCode === 404) {
        console.log(stateLoginDetails);
        setAlertMessage("Access denied. Please check username and password.!!");
        if (stateLoginDetails?.attempt >= 3) {
          if (stateLoginDetails.data === "") {
            setAlertMessage(
              "No such user exists. Please check username and try again!!"
            );
          } else {
            Swal.fire({
              icon: "warning",
              title: "Uh oh!!",
              text: "You have entered the wrong credentials for 3 times. Please answer your security question to proceed!!",
              confirmButtonColor: "#8f6d26",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/agent/verifySecurityQuestion", { replace: true });
              }
            });
          }
        }
      }
    }
  }, [stateLoginDetails]);

  useEffect(() => {
    if (stateAgent.apiStatus === "failed") {
      setAlertMessage("Something went wrong while login. Please try again!!");
    }
    if (stateAgent.apiStatus === "success") {
      ls.set("fmsToken", stateAgent?.data?.jwtToken);
      ls.set("log_meta", stateAgent?.data?.agent);
      navigate("/admin/dashboard", { replace: true });
      const payload = {
        agentUUID: stateAgent?.data?.agent?.agentUUID,
      };
      dispatch(updateLoginTime(payload));
    }
  }, [stateAgent]);

  return (
    <Box sx={{ marginTop: "1rem", textAlign: "center" }}>
      {alertMessage && (
        <Alert severity="error" sx={{ margin: "0 0 1rem 0 " }}>
          {alertMessage}
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth margin="dense">
          <Controller
            control={control}
            ref={ref}
            name="agentName"
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState?.error}
                helperText={fieldState?.error?.message}
                label="Username"
                size="small"
                variant="outlined"
                color="secondary"
                type="text"
                placeholder="johndoe"
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth margin="dense">
          <Controller
            control={control}
            ref={ref}
            name="password"
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState?.error}
                helperText={fieldState?.error?.message}
                label="Password"
                size="small"
                variant="outlined"
                color="secondary"
                type={!!showPassword ? "text" : "password"}
                placeholder="********"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        {!!showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </FormControl>
        <FormControl
          fullWidth
          margin="dense"
          sx={{ display: "flex", alignItems: "flex-end", margin: "1rem 0" }}
        >
          <Link href="/agent/forgotPassword" underline="hover">
            Forgot password
          </Link>
        </FormControl>
        <FormControl fullWidth margin="dense">
          <Button variant="contained" color="secondary" type="submit">
            Login
          </Button>
        </FormControl>
      </form>
      <Loading
        open={
          stateLoginDetails.apiStatus === "pending" ||
          stateAgent.apiStatus === "pending"
        }
      />
    </Box>
  );
};

export default LoginForm;
