import { Button, Container, Grid, Stack } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SecureLS from "secure-ls";
import { revertAll } from "../features/Authentication/AuthenticationSlice";
import Loading from "./Loading";

const Navbar = ({ image }) => {
  const ls = new SecureLS({ encodingType: "aes" });
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDesigns = () => {
    navigate("/admin/designs", { replace: true });
  };
  const handleDashboard = () => {
    navigate("/admin/dashboard", { replace: true });
  };

  const handleLogout = async () => {
    setIsLoading(true);
    setTimeout(() => {
      dispatch(revertAll());
      ls.clear();
      window.location = "/login";
    }, 1500);
  };

  const menuItems = [
    {
      label: "Dashboard",
      id: 0,
      handler: handleDashboard,
      variant: "contained",
    },
    {
      label: "Designs",
      id: 1,
      handler: handleDesigns,
      variant: "contained",
    },
    {
      label: "Logout",
      id: 2,
      handler: handleLogout,
      variant: "outlined",
    },
  ];
  return (
    <Container
      maxWidth="100%"
      sx={{ background: "#fff", boxShadow: "0 2px 4px 0 #00000021" }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          background: "#fff",
          padding: ".5rem",
          justifyContent: "space-between",
        }}
      >
        <Grid
          item
          xs={12}
          md={2}
          sx={{
            "& img": {
              xs: { width: "50%" },
              sm: { width: "25%" },
              md: { width: "100%" },
              lg: { width: "45%" },
            },
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src={image} alt="Darek logo" />
        </Grid>

        <Grid
          item
          xs={12}
          md={7}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {menuItems.map((item) => (
            <Stack direction="row" spacing={4} key={item.id}>
              <Button
              size="small"
                variant={item.variant}
                sx={{ margin: "0 .5rem" }}
                onClick={item.handler}
              >
                {item.label}
              </Button>
            </Stack>
          ))}
        </Grid>
      </Grid>
      <Loading open={!!isLoading} />
    </Container>
  );
};

export default Navbar;
