import React from "react";
import FormCard from "../../components/FormCard";
import logo from '../../assets/new-logo.svg'
import OtpForm from "./forms/OtpForm";

const Otp = () => {
  return (
    <FormCard
      logo={logo}
      title="OTP Verification"
      form={<OtpForm />}
      gridWidthMd={6}
      gridWidthSm={6}
      gridWidthLg={3}
    />
  );
};

export default Otp;
