import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Box, Button, FormControl, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { otpSchema } from "../utils/ValidationSchema";
import {
  getAgentState,
  getForgotPasswordState,
  getLoginDetailsState,
  getVerifyOtpState,
} from "../AuthenticationSlice";
import {
  agentLogin,
  forgotPassword,
  updateLoginTime,
  verifyLoginOTP,
} from "../AuthenticationApis";
import SecureLS from "secure-ls";
import Swal from "sweetalert2";
import Loading from "../../../components/Loading";
import CountDownTimer from "../../../components/CountdownTimer";

const OtpForm = () => {
  const { control, ref, handleSubmit, reset } = useForm({
    mode: "onBlur",
    resolver: yupResolver(otpSchema),
  });

  const ls = new SecureLS({ encodingType: "aes" });

  const navigate = useNavigate(); //usenavigate hook
  const dispatch = useDispatch(); //usedispatch hook
  const location = useLocation(); // uselocation hook
  const agentCredentials = location?.state?.credentials; // if location history is login page
  const agentUUID = location?.state?.agentUUID; // if location history is forgot password page
  const userData = location?.state?.userData;
  const agentState = useSelector(getLoginDetailsState); //states of logindetails api
  const stateVerifyOtp = useSelector(getVerifyOtpState); //states of verify api
  const stateLogin = useSelector(getAgentState); //states of login api
  const [timer, setTimer] = useState(true);

  const [errorAlert, setErrorAlert] = useState(""); // component state to show error message alert

  const onSubmit = async (values) => {
    const payload = {
      otpNumber: values.otp,
      agentUUID: agentUUID ? agentUUID : agentState?.data?.agentUUID,
    };
    await dispatch(verifyLoginOTP(payload)).unwrap();
  };

  useEffect(() => {
    const loginUser = async () => {
      await dispatch(agentLogin(agentCredentials)).unwrap();
    };

    if (stateVerifyOtp.apiStatus === "failed") {
      if (stateVerifyOtp.statusCode === 400) {
        Swal.fire({
          icon: "error",
          title: "Uh Oh!!",
          text: "Something went wrong while fetching agent information. Please try again!!",
          confirmButtonColor: "#8f6d26",
        }).then((res) => {
          if (res.isConfirmed) {
            navigate("/account/signin", { replace: true });
          }
        });
      } else {
        setErrorAlert(
          stateVerifyOtp?.message
            ? stateVerifyOtp.message
            : "Something went wrong while verifying the OTP. Please try again later!!"
        );
      }
    }
    if (stateVerifyOtp.apiStatus === "success") {
      agentUUID === undefined // location history is login
        ? loginUser() // login user
        : navigate(`/agent/resetPassword/${agentUUID}`, {
            // if location history is forgot password, redirect to reset password
            replace: true,
          });
    }
  }, [stateVerifyOtp]);

  useEffect(() => {
    if (stateLogin.apiStatus === "failed") {
      setErrorAlert(
        stateLogin?.message
          ? stateLogin.message
          : "Something went wrong while login. Please try again later!!"
      );
    }
    if (stateLogin.apiStatus === "success") {
      ls.set("fmsToken", stateLogin.data.jwtToken);
      const payload = {
        agentUUID: agentState?.data?.agentUUID,
      };
      dispatch(updateLoginTime(payload));
      navigate("/admin/dashboard", { replace: true });
    }
  }, [stateLogin]);

  const resendOtp = async () => {
    await dispatch(forgotPassword(userData)).unwrap();
  };

  const _rsForgotPasswordState = useSelector(getForgotPasswordState);

  useEffect(() => {
    if (_rsForgotPasswordState?.apiStatus === "failed") {
      Swal.fire({
        icon: "error",
        title: "uh Ohh!!",
        text: _rsForgotPasswordState?.message
          ? _rsForgotPasswordState?.message
          : "Something went wrong while sending OTP. Please try again!!",
        allowOutsideClick: false,
        confirmButtonColor: "#8f6d26",
      });
    }
    if (_rsForgotPasswordState?.apiStatus === "success") {
      setTimer(true);
    }

    return () => {
      setTimer(false);
    };
  }, [_rsForgotPasswordState]);

  const handleTimerComplete = () => {
    setTimer(false);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        {errorAlert && (
          <Alert severity="error" sx={{ marginBottom: "1rem" }}>
            {errorAlert}
          </Alert>
        )}
        <FormControl fullWidth margin="dense">
          <Controller
            control={control}
            ref={ref}
            name="otp"
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState?.error}
                helperText={fieldState?.error?.message}
                label="OTP"
                size="small"
                color="secondary"
                type="text"
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth margin="dense">
          <Button variant="contained" color="secondary" type="submit">
            Verify Now
          </Button>
        </FormControl>
        <FormControl fullWidth margin="dense">
          <Button
            variant="outlined"
            color="secondary"
            type="button"
            onClick={resendOtp}
            disabled={!!timer}
          >
            {!!timer ? (
              <CountDownTimer
                isOverlay={!timer}
                isLoading={""}
                newQr={""}
                timerFor="Resend OTP"
                time={180}
                handleTimerFinish={handleTimerComplete}
                format="minutes"
                isReset={!timer}
              />
            ) : (
              "Resend OTP"
            )}
          </Button>
        </FormControl>
      </form>

      <Loading
        open={
          stateVerifyOtp.apiStatus === "pending" ||
          stateLogin.apiStatus === "pending"
        }
      />
    </Box>
  );
};

export default OtpForm;
