import { configureStore } from "@reduxjs/toolkit";
import UploadDesignReducer from "../features/UploadDesign/UploadDesignSlice";
import authReducer from "../features/Authentication/AuthenticationSlice";
export const store = configureStore({
  reducer: {
    uploadDesign: UploadDesignReducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleWare) =>
    getDefaultMiddleWare({
      serializableCheck: false,
    }),
});
