import { createAction, createSlice } from "@reduxjs/toolkit";
import {
  deactivateRegistration,
  downloadimages,
  generateToken,
  listDesigns,
  saveDocDataDb,
  submitData,
  takeAction,
  uploadDocsToS3Api,
  viewDesigns,
} from "./UploadDesignApis";

const initialState = {
  apiToken: {
    apiStatus: "idle",
    statusCode: null,
    message: "",
    data: [],
  },
  register: {
    apiStatus: "idle",
    statusCode: null,
    message: "",
    data: [],
  },
  saveDocumentToDb: {
    apiStatus: "idle",
    statusCode: null,
    message: "",
    data: [],
  },
  saveDocumentToS3: {
    apiStatus: "idle",
    statusCode: null,
    message: "",
    data: [],
  },
  deactivateReg: {
    apiStatus: "idle",
    statusCode: null,
    message: "",
    data: [],
  },
  designList: {
    apiStatus: "idle",
    statusCode: null,
    message: "",
    data: [],
  },
  designView: {
    apiStatus: "idle",
    statusCode: null,
    message: "",
    data: [],
  },
  viewImages: {
    apiStatus: "idle",
    statusCode: null,
    message: "",
    data: [],
  },
  regAction: {
    apiStatus: "idle",
    statusCode: null,
    message: "",
    data: [],
  },
};

export const revertViewDesign = createAction("REVERT_VIEW_DESIGN");
export const revertListImages = createAction("REVERT_LIST_IMAGES");
export const revertViewImages = createAction("REVERT_VIEW_IMAGES");
export const revertAll = createAction("REVERT_ALL");

const uploadDesignSlice = createSlice({
  name: "uploadDesign",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(revertViewDesign, (state) => {
        state.designView = initialState.designView;
      })
      .addCase(revertListImages, (state) => {
        state.listImages = initialState.listImages;
      })
      .addCase(revertViewImages, (state) => {
        state.viewImages = initialState.viewImages;
      })
      .addCase(generateToken.pending, (state) => {
        state.apiToken.apiStatus = "pending";
      })
      .addCase(generateToken.rejected, (state, action) => {
        state.apiToken.apiStatus = "failed";
        state.apiToken.error = true;
        state.apiToken.statusCode = action?.payload?.status;
        state.apiToken.message =
          typeof action?.payload?.data === "string"
            ? action?.payload?.data
            : action?.payload?.data?.message;
      })
      .addCase(generateToken.fulfilled, (state, action) => {
        state.apiToken.apiStatus = "success";
        state.apiToken.error = false;
        state.apiToken.statusCode = action?.payload?.status;
        state.apiToken.errorMessage =
          typeof action?.payload?.data === "string"
            ? action?.payload?.data
            : action?.payload?.data?.message;
        state.apiToken.data = action?.payload?.data;
      })
      .addCase(submitData.pending, (state) => {
        state.register.apiStatus = "pending";
      })
      .addCase(submitData.rejected, (state, action) => {
        state.register.apiStatus = "failed";
        state.register.error = true;
        state.register.statusCode = action?.payload?.status;
        state.register.message =
          typeof action?.payload?.data === "string"
            ? action?.payload?.data
            : action?.payload?.data?.message;
      })
      .addCase(submitData.fulfilled, (state, action) => {
        state.register.apiStatus = "success";
        state.register.error = false;
        state.register.statusCode = action?.payload?.status;
        state.register.errorMessage =
          typeof action?.payload?.data === "string"
            ? action?.payload?.data
            : action?.payload?.data?.message;
        state.register.data = action?.payload?.data;
      })
      .addCase(saveDocDataDb.pending, (state) => {
        state.saveDocumentToDb.apiStatus = "pending";
      })
      .addCase(saveDocDataDb.rejected, (state, action) => {
        state.saveDocumentToDb.apiStatus = "failed";
        state.saveDocumentToDb.error = true;
        state.saveDocumentToDb.statusCode = action?.payload?.status;
        state.saveDocumentToDb.message =
          typeof action?.payload?.data === "string"
            ? action?.payload?.data
            : action?.payload?.data?.message;
      })
      .addCase(saveDocDataDb.fulfilled, (state, action) => {
        state.saveDocumentToDb.apiStatus = "success";
        state.saveDocumentToDb.error = false;
        state.saveDocumentToDb.statusCode = action?.payload?.status;
        state.saveDocumentToDb.errorMessage =
          typeof action?.payload?.data === "string"
            ? action?.payload?.data
            : action?.payload?.data?.message;
        state.saveDocumentToDb.data = action?.payload?.data;
      })
      .addCase(uploadDocsToS3Api.pending, (state) => {
        state.saveDocumentToS3.apiStatus = "pending";
      })
      .addCase(uploadDocsToS3Api.rejected, (state, action) => {
        state.saveDocumentToS3.apiStatus = "failed";
        state.saveDocumentToS3.error = true;
        state.saveDocumentToS3.statusCode = action?.payload?.status;
        state.saveDocumentToS3.message =
          typeof action?.payload?.data === "string"
            ? action?.payload?.data
            : action?.payload?.data?.message;
      })
      .addCase(uploadDocsToS3Api.fulfilled, (state, action) => {
        state.saveDocumentToS3.apiStatus = "success";
        state.saveDocumentToS3.error = false;
        state.saveDocumentToS3.statusCode = action?.payload?.status;
        state.saveDocumentToS3.errorMessage =
          typeof action?.payload?.data === "string"
            ? action?.payload?.data
            : action?.payload?.data?.message;
        state.saveDocumentToS3.data = action?.payload?.data;
      })
      .addCase(deactivateRegistration.pending, (state) => {
        state.deactivateReg.apiStatus = "pending";
      })
      .addCase(deactivateRegistration.rejected, (state, action) => {
        state.deactivateReg.apiStatus = "failed";
        state.deactivateReg.error = true;
        state.deactivateReg.statusCode = action?.payload?.status;
        state.deactivateReg.message =
          typeof action?.payload?.data === "string"
            ? action?.payload?.data
            : action?.payload?.data?.message;
      })
      .addCase(deactivateRegistration.fulfilled, (state, action) => {
        state.deactivateReg.apiStatus = "success";
        state.deactivateReg.error = false;
        state.deactivateReg.statusCode = action?.payload?.status;
        state.deactivateReg.errorMessage =
          typeof action?.payload?.data === "string"
            ? action?.payload?.data
            : action?.payload?.data?.message;
        state.deactivateReg.data = action?.payload?.data;
      })
      .addCase(listDesigns.pending, (state) => {
        state.designList.apiStatus = "pending";
      })
      .addCase(listDesigns.rejected, (state, action) => {
        state.designList.apiStatus = "failed";
        state.designList.error = true;
        state.designList.statusCode = action?.payload?.status;
        state.designList.message =
          typeof action?.payload?.data === "string"
            ? action?.payload?.data
            : action?.payload?.data?.message;
      })
      .addCase(listDesigns.fulfilled, (state, action) => {
        state.designList.apiStatus = "success";
        state.designList.error = false;
        state.designList.statusCode = action?.payload?.status;
        state.designList.errorMessage =
          typeof action?.payload?.data === "string"
            ? action?.payload?.data
            : action?.payload?.data?.message;
        state.designList.data = action?.payload?.data;
      })
      .addCase(viewDesigns.pending, (state) => {
        state.designView.apiStatus = "pending";
      })
      .addCase(viewDesigns.rejected, (state, action) => {
        state.designView.apiStatus = "failed";
        state.designView.error = true;
        state.designView.statusCode = action?.payload?.status;
        state.designView.message =
          typeof action?.payload?.data === "string"
            ? action?.payload?.data
            : action?.payload?.data?.message;
      })
      .addCase(viewDesigns.fulfilled, (state, action) => {
        state.designView.apiStatus = "success";
        state.designView.error = false;
        state.designView.statusCode = action?.payload?.status;
        state.designView.errorMessage =
          typeof action?.payload?.data === "string"
            ? action?.payload?.data
            : action?.payload?.data?.message;
        state.designView.data = action?.payload?.data;
      })
      .addCase(downloadimages.pending, (state) => {
        state.viewImages.apiStatus = "pending";
      })
      .addCase(downloadimages.rejected, (state, action) => {
        state.viewImages.apiStatus = "failed";
        state.viewImages.error = true;
        state.viewImages.statusCode = action?.payload?.status;
        state.viewImages.message =
          typeof action?.payload?.data === "string"
            ? action?.payload?.data
            : action?.payload?.data?.message;
      })
      .addCase(downloadimages.fulfilled, (state, action) => {
        state.viewImages.apiStatus = "success";
        state.viewImages.error = false;
        state.viewImages.statusCode = action?.payload?.status;
        state.viewImages.errorMessage =
          typeof action?.payload?.data === "string"
            ? action?.payload?.data
            : action?.payload?.data?.message;
        state.viewImages.data = action?.payload?.data;
      })
      .addCase(takeAction.pending, (state) => {
        state.regAction.apiStatus = "pending";
      })
      .addCase(takeAction.rejected, (state, action) => {
        state.regAction.apiStatus = "failed";
        state.regAction.error = true;
        state.regAction.statusCode = action?.payload?.status;
        state.regAction.message =
          typeof action?.payload?.data === "string"
            ? action?.payload?.data
            : action?.payload?.data?.message;
      })
      .addCase(takeAction.fulfilled, (state, action) => {
        state.regAction.apiStatus = "success";
        state.regAction.error = false;
        state.regAction.statusCode = action?.payload?.status;
        state.regAction.errorMessage =
          typeof action?.payload?.data === "string"
            ? action?.payload?.data
            : action?.payload?.data?.message;
        state.regAction.data = action?.payload?.data;
      })
  },
});

export const getTokenState = (state) => {
  return state.uploadDesign.apiToken;
};
export const getSubmitDataState = (state) => {
  return state.uploadDesign.register;
};
export const getDocsToDbState = (state) => {
  return state.uploadDesign.saveDocumentToDb;
};
export const getDocsToS3State = (state) => {
  return state.uploadDesign.saveDocumentToS3;
};
export const getDeactivateRegState = (state) => {
  return state.uploadDesign.deactivateReg;
};
export const getDesignListState = (state) => {
  return state.uploadDesign.designList;
};
export const getDesignViewState = (state) => {
  return state.uploadDesign.designView;
};
export const getListImagesState = (state) => {
  return state.uploadDesign.listImages;
};
export const getViewImagesState = (state) => {
  return state.uploadDesign.viewImages;
};
export const getRegActionState = (state) => {
  return state.uploadDesign.regAction;
};

export default uploadDesignSlice.reducer;
