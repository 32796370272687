import React from "react";
import LoginForm from "./forms/LoginForm";
import FormCard from "../../components/FormCard";
import logo from "../../assets/new-logo.svg";

const Login = () => {
  return (
    <FormCard
      title="Agent Login"
      logo={logo}
      form={<LoginForm />}
      gridWidthMd={6}
      gridWidthSm={6}
      gridWidthLg={3}
    />
  );
};

export default Login;
