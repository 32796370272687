import React from "react";
import FormCard from "../../components/FormCard";
import SecurityQuestionsForm from "./forms/SecurityQuestionsForm";

const SecurityQuestions = () => {
  return (
    <FormCard
      logo=""
      title="Security Questions"
      form={<SecurityQuestionsForm />}
      gridWidthMd={8}
      gridWidthSm={8}
      gridWidthLg={5}
    />
  );
};

export default SecurityQuestions;
