import {
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React from "react";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import ErrorIcon from "@mui/icons-material/Error";
import ErrorImg from "../assets/404.png";
const Http404 = () => {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        margin: "auto",
        alignItems: "center",
      }}
    >
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Card elevation={6}>
          <CardContent sx={{ textAlign: "center" }}>
            <Typography
              variant="h1"
              color="secondary"
              sx={{ textAlign: "center" }}
            >
              {/* <ErrorIcon color="error" sx={{ fontSize: 60 }} /> */}
              <img width="80%" src={ErrorImg} alt="error" />
            </Typography>
            <Typography
              variant="h5"
              color="secondary"
              sx={{ textAlign: "center" }}
            >
              Oops!!
            </Typography>
            <Typography
              gutterBottom
              variant="body1"
              color="secondary"
              sx={{ textAlign: "center" }}
            >
              The page you are looking for is not found. Please visit our
              website for any further information.{" "}
            </Typography>
            <Button
              sx={{ marginTop: "1rem" }}
              variant="contained"
              color="primary"
              onClick={() => {
                window.location.href = "https://darek.ae";
              }}
            >
              Visit Website
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Http404;
